import React, { Component } from "react";
import SubMenu from "../components/menuItems/SubMenu";
import MenuNav from "../components/menuItems/MenuNav";
import Layout from "../components/layout";
import { appWrap } from "../styles/shared.module.css";

export default class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = { active: "Pastries" };
        this.changeActive = this.changeActive.bind(this);
    }
    changeActive(active) {
        this.setState({ active });
    }
    render() {
        return (
            <Layout>
                <div className={appWrap} key={"menu"}>
                    <MenuNav
                        sections={navArray}
                        handleClick={this.changeActive}
                        active={this.state.active}
                    />
                    {menu[this.state.active].map((sub, i, a) => (
                        <SubMenu
                            key={i}
                            category={sub.category}
                            items={sub.items}
                        />
                    ))}
                </div>
            </Layout>
        );
    }
}

let navArray = [
    "Pastries",
    "Tarts & Cakes",
    "Savory Baked Goods",
    "Breads",
    "Sandwiches",
    "Beverages",
    "Deals"
];
let menu = {
    Pastries: [
        {
            category: "Croissants",
            items: [
                { item: "Plain Croissant", price: "2.25" },
                { item: "Chocolate Croissant", price: "2.50" },
                { item: "Almond Croissant", price: "3.25" },
                { item: "Almond Chocolate Croissant", price: "3.50" }
            ]
        },
        {
            category: "Other Pastries",
            items: [
                { item: "Canelé", price: "1.20" },
                { item: "Chocolate Moelleux", price: "3.50" },
                { item: "Bread Pudding", price: "2.50" },
                { item: "Strudel", price: "4.50" },
                { item: "Raisin Whirl", price: "2.99" },
                { item: "Coconut Rock", price: "1.99" },
                { item: "Eclair", price: "1.99" },
                { item: "Kouign-amann", price: "3.99" },
                { item: "Apple Turnover", price: "2.99" }
            ]
        },
        {
            category: "Mini Pastries",
            items: [
                { item: "Mini Croissant", price: "1.25" },
                { item: "Mini Chocolate Croissant", price: "1.25" },
                { item: "Mini Raisin Whirl", price: "1.25" },
                { item: "Mini Canelé", price: "0.60" },
                { item: "Mini Apple Turnover", price: "1.50" }
            ]
        }
    ],
    "Tarts & Cakes": [
        {
            category: "Tarts",
            items: [
                { item: "Almond Caramel Tart", price: "15.99" },
                { item: "Apple Tart", price: "14.99" },
                { item: "Apricot Tart", price: "15.99" },
                { item: "Blueberry Tart with Custard Cream", price: "18.99" },
                { item: "Cherry Tart", price: "15.99" },
                { item: "Lemon Tart", price: "14.99" },
                { item: "Peach Tart", price: "14.99" },
                { item: "Pear Tart", price: "14.99" },
                { item: "Raspberry Tart", price: "19.99" },
                { item: "Strawberry Tart", price: "15.99" }
            ]
        },
        {
            category: "Tartlettes",
            items: [
                { item: "Fruit Tartelette", price: "4.99" },
                { item: "Berry Tartelette", price: "4.99" },
                { item: "Blueberry Tartelette", price: "4.99" },
                { item: "Cherry Tartelette", price: "4.99" },
                { item: "Raspberry Tartelette", price: "5.50" }
            ]
        },
        {
            category: "Cakes",
            items: [
                { item: "La Bohemia Cake", price: "3.99" },
                { item: "Honey Cake", price: "3.99" }
            ]
        }
    ],
    "Savory Baked Goods": [
        {
            category: "Savory Baked Goods",
            items: [
                { item: "Cheese Envelope", price: "3.99" },
                { item: "Cheese Puff", price: "0.70" },
                { item: "Chicken Meatball Roll", price: "1.50" },
                { item: "Kloubasnek", price: "2.50" },
                { item: "Spinach Quiche Small", price: "3.99" },
                { item: "Turkey Quiche Small", price: "3.99" },
                { item: "Spinach & Mozzarella Basket", price: "3.99" },
                { item: "Vegan Spinach Turnover", price: "3.99" }
            ]
        }
    ],
    Breads: [
        {
            category: "Baguettes",
            items: [
                { item: "Traditional Baguette", price: "2.50" },
                { item: "Wholewheat Baguette", price: "2.99" },
                { item: "Buckwheat Baguette", price: "2.99" },
                { item: "Multigrain Baguette", price: "2.99" },
                { item: "Epi Baguette", price: "2.50" }
            ]
        },
        {
            category: "Other Breads",
            items: [
                { item: "Boulot", price: "4.99" },
                { item: "Brioche", price: "4.99" },
                { item: "Brioche Small", price: "1.99" },
                { item: "Buckwheat Bread", price: "9.00" },
                { item: "Multigrain Loaf", price: "3.75" },
                { item: "Wholewheat Loaf", price: "3.75" },
                { item: "Whole Miche", price: "13.99" },
                { item: "1/2 Miche", price: "6.99" },
                { item: "1/4 Miche", price: "3.50" }
            ]
        }
    ],
    Sandwiches: [
        {
            category: "Sandwiches",
            items: [
                { item: "Atlantique Sandwich", price: "6.50" },
                { item: "BLT Sandwich", price: "6.25" },
                { item: "Briard Sandwich", price: "5.50" },
                { item: "Caprese Sandwich", price: "5.99" },
                { item: "Complet Sandwich", price: "6.25" },
                { item: "Dry Sausage Sandwich", price: "5.99" },
                { item: "Ham & Cheese Sandwich", price: "5.50" },
                { item: "Pan Bagnat Sandwich", price: "6.25" },
                { item: "Panama Sandwich", price: "5.99" },
                { item: "Roast Beef Sandwich", price: "6.25" },
                { item: "Vegan Sandwich", price: "5.75" }
            ]
        }
    ],
    Beverages: [
        {
            category: "Beverages",
            items: [
                { item: "Coca Cola", price: "1.25" },
                { item: "Diet Cola", price: "1.25" },
                { item: "7-Up", price: "1.25" },
                { item: "Sprite", price: "1.25" },
                { item: "Fanta", price: "1.25" },
                { item: "Ice Tea Bottle", price: "1.75" },
                { item: "Ice Tea Canned", price: "1.25" },
                { item: "Iced Coffee", price: "2.25" },
                { item: "Apple Juice", price: "1.75" },
                { item: "Mango Juice", price: "1.75" },
                { item: "Orange Juice", price: "1.75" },
                { item: "Organic Kids' Juice", price: "1.75" },
                { item: "Kids Juice", price: "0.99" },
                { item: "San Pellegrino Soda", price: "1.75" },
                { item: "Perrier", price: "1.99" },
                { item: "Water", price: "0.99" }
            ]
        },
        {
            category: "Hot Beverages",
            items: [
                { item: "Regular Coffee Small", price: "1.25" },
                { item: "Regular Coffee Large", price: "1.75" },
                { item: "Espresso", price: "1.50" },
                { item: "Cappuccino", price: "2.50" },
                { item: "Latte", price: "2.50" },
                { item: "Hot Chocolate", price: "1.75" },
                { item: "Black Tea", price: "1.25" },
                { item: "Herbal Tea", price: "1.75" }
            ]
        }
    ],
    Deals: [
        {
            category: "Deals",
            items: [
                {
                    item:
                        "Breakfast Sandwich Meal (Ham & Cheese or Egg & Cheese Sandwich + Small Coffee)",
                    price: "5.99"
                },
                {
                    item:
                        "Lunch Meal (Sandwich of Choice + Mini Canelé + Soda)",
                    price: "6.99"
                },
                {
                    item:
                        "Thursday Sandwich Meal (Sandwich of Choice + Baguette + Almond Croissant + Soda)",
                    price: "10.00"
                }
            ]
        }
    ]
};
